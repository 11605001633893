import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Button from '@material-ui/core/Button';
import FormStyle from './FormStyle';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


const Form = () => {
  const initialdata = {
    name:'',
    email:'',
    message:'',
    flyingFrom:'',
    destination:'',
    travelers:'',
    departing: new Date(),
    returning:new Date(),
  };
  const sendEmail=(e)=> {
    e.preventDefault();
  
    emailjs.sendForm('service_o80en7v', 'template_cgxojr9', e.target, 'user_BHTX0Q8IgsCPViwIpbyIk')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      setData(initialdata);
  };
  const [data, setData] = useState(initialdata);
  const onInputChange = (event,params) => {
    // debugger
    switch (params) {
      case 'departing':
        return setData({ ...data, departing: event });
      case 'returning':
        return setData({ ...data, returning: event });
      default:
        return setData({ ...data, [event.target.name]: event.target.value });
    }
}
  return (
    <FormStyle>
    <form onSubmit={(e)=>sendEmail(e)}>
      <h2>Send your Message</h2>
      <div className="form-group">
      <div className="form-input-wrap">
        <label htmlFor="name">Name</label>
        <TextField
          required
          id="outlined-required"
          label="Required"
          variant="outlined"
          name="name"
          value={data.name} 
          onChange={event => onInputChange(event)}
        />
      </div>
      <div className="form-input-wrap">
        <label htmlFor="exampleInputEmail1">Email address</label>
        <TextField
          required
          id="outlined-required"
          label="Required"
          variant="outlined"
          name="email"
          type="email"
          value={data.email}
          onChange={event => onInputChange(event)}
        />
      </div>
      <div className="form-input-wrap">
        <label htmlFor="exampleInputEmail1">Flying From</label>
        <TextField
          variant="outlined"
          name="flyingFrom"
          value={data.flyingFrom} 
          placeholder="Flying From"
          onChange={event => onInputChange(event)}
        />
      </div>
      <div className="form-input-wrap">
        <label htmlFor="exampleInputEmail1">Destination</label>
        <TextField
          variant="outlined"
          name="destination"
          value={data.destination} 
          placeholder="Destination"
          onChange={event => onInputChange(event)}
        />
      </div>
      <div className="form-input-wrap">
        <label htmlFor="exampleInputEmail1">Total Travelers</label>
        <TextField
          variant="outlined"
          name="travelers"
          type="number"
          value={data.travelers} 
          placeholder="Total Travelers"
          onChange={event => onInputChange(event)}
        />
      </div>
      <div className="form-input-wrap">
        <label htmlFor="exampleInputEmail1">Departing</label>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          value={data.departing}
          name="departing"
          onChange={event => onInputChange(event,'departing')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
    </MuiPickersUtilsProvider>
      </div>
      <div className="form-input-wrap">
      <label htmlFor="exampleReturning">Returning</label>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          value={data.returning}
          name="returning"
          onChange={event => onInputChange(event,'returning')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
    </MuiPickersUtilsProvider>
    </div>
      <div className="form-input-wrap">
        <label htmlFor="message">Message</label>
        <TextField
          id="outlined-multiline-flexible"
          label="Multiline"
          multiline
          rowsMax={4}
          name="message"
          value={data.message}
          onChange={event => onInputChange(event,'date')}
          variant="outlined"
          size="normal"
        />
      </div>
      </div>
      <Button type="submit" variant="contained" size="large" color="primary">Send</Button>
    </form>
    </FormStyle>
  )
}

export default Form;
