export const MenuBarData = [
  {
    title: 'Home',
    path:'/',
    cName: 'nav-text',
  },
  {
    title: 'Contact Us',
    path:'/contact-us',
    cName: 'nav-text',
  }
]