import styled from 'styled-components';

const AppWrapper = styled.div`
.App {
  overflow-x: hidden;
  @media screen and (max-width: 768px) {
    padding: 6px 16px;
  }
} 

a{
  color:#1e73be;
  font-weight: 700;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
     img{
      width: 100%;
    }}
`;

export default AppWrapper;