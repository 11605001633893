import AppWrapper from './AppWrapper';
import Main from './components';
import TopHeader from './components/TopHeader';
import {
  Redirect, Router, Route, Switch,
} from 'react-router-dom';
import Contact from './components/ContactForm';

const App=()=> {
  return (
    <AppWrapper>
      <TopHeader/>    
      <Switch>
            <Route
              exact
              path="/"
              component={Main}
            />
            <Route
              exact
              path="/contact-us"
              component={Contact}
            />
            </Switch>
    </AppWrapper>
  );
}

export default App;
