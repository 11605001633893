import styled from 'styled-components';

const SliderStyle = styled.div`
  position: relative;
  // height: 100vh;
  // width: 100vw;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  .arrow{
    display: flex;
        position: absolute;
        top: 50%;
        
        height: 50px;
        width: 50px;
        justify-content: center;
        background: white;
        border-radius: 50%;
        cursor: pointer;
        align-items: center;
        transition: transform ease-in 0.1s;
  
        &:hover {
          transform: scale(1.1);
        }
  
        img {
         &:focus {
            outline: 0;
          }
        }
  
  }
  .dot{
    padding: 10px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 50%;
    .black{
      background: black;
    }
    .white{
      background: white;
    }
  }
  .dots{
    position: absolute;
    bottom: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slide{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .sliderContent{
    
      display: flex;
  }
`;

export default SliderStyle;