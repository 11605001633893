import React from 'react'
import Info from './Info';
import HomeStyle from './HomeStyle';
import Icon from '../Icon';
import Slider from '../ImageSlider/Slider';
import ImageSlider from './ImageSlider';
import Cards from '../Card/Cards';

const images=[
  "/images/img-2.jpg",
  "/images/img-4.png",
  "/images/img-1.png",
  "/images/img-3.jpg",
]

const Home=()=> {
  return (
    <>
    <HomeStyle>
      <ImageSlider slides={images}/>
      <div className="support">
      <Icon iconName="info-circle" />
        <span>
          <a href='https://travel.state.gov/content/travel.html' target="_blank">
            Travel.State.Gov
          </a>
        </span>
      </div>
      <Info/>
      <Cards/>
    </HomeStyle>
    </>
  )
}

export default Home;
