import styled from 'styled-components';

const MenuBarStyle = styled.div`
.menuBar{
  height: 45px;
  padding: 0px 150px;
  overflow: hidden;
  background-color: #ebebeb;
  color: black;
  .title{
    display: none;
    @media screen and (max-width: 768px) {
      padding:0px;
      display: flex;
      color: #1e73be;
      font-size: 25px;
      padding: 6px 16px;
    }
  }
  .icon{
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    cursor: pointer;
    padding-right: 20px;
    }  
  }
  a {
    float:left;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    color: black;
    font-size: 500;
    padding-right: 30px;
    padding-top: 12px;
    @media screen and (max-width: 768px) {
      display:none;
      padding:0px;
      
    }
  }
  a:hover {
    color: #1e73be;
  }
  a.active {
    color: #1e73be;
  }
  @media screen and (max-width: 768px) {
    padding:0px;
  }
}
.nav-menu{
  background-color:#ebebeb;
  width: 330px;
  height: 100vh;
  display: flex;
  /* justify-content: center; */
  position: fixed;
  top:0;
  left: -100%;
  z-index: 12;
  transition: 850ms;
  
  
}
.nav-menu.active{
    left: 0;
    transition: 350ms;
  }

.nav-text{
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  a{
    text-decoration: none;
    color: #1e73be;
    font-size: 16px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  &:hover {
   background-color: #f5f5f5;
   border-radius: 4px;
  }
}

.nav-menu-items{
  width: 100%;
}
.navbar-toggle{
    list-style: none;
    padding: 0 20px;
    padding-top: 8px;
}
`;

export default MenuBarStyle;