import React from 'react';
import Footer from './Footer';
import Home from './Home';

const Main = ()=> {
  return (
   <>
    <Home/>
    <Footer/>
    </>
  )
}

export default Main;
