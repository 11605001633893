import styled from 'styled-components';

const TopHeaderStyled = styled.div`

.topheader{
  display: flex;
  background: #ebebeb;
  height: 190px;
  padding: 16px 150px;
  justify-content: space-between;
  .topheader__logo{
    // width:75%;
    h1{
      font-size:55px;
      font-weight: normal;
    }
    color: #1e73be;
  }
  .topheader__contact{
    padding: 16px;
    width:25%;
    color:#1e73be;
    .callUs{
      font-size: 24px;
    }
    .number{
      font-weight: bold;
      font-size: 34px;
    }
    .tollfree{
      display: flex;
      p {
        color: black;
        margin:0px;
        padding-right: 10px;
        margin-left: 20px;
      }
      a {
        color:#1e73be;
        text-decoration: none;
      }
    }
    
  }
  @media screen and (max-width: 768px) {
    display:none;
  }
}

`;

export default TopHeaderStyled;