// import React from 'react'
// import {Carousel} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const ImageSlider = () => {
//   return (
//     <Carousel>
//         <Carousel.Item interval={2000}>
//           <img
//             className="d-block w-100"
//             src="/images/annapurna.png"
//             alt="annapurna"
//           />
          
//         </Carousel.Item>
//         <Carousel.Item interval={2000}>
//           <img
//             src="/images/ValleyLightView.jpg"
//             alt="Second slide"
//           />
          
//         </Carousel.Item>
//         <Carousel.Item interval={2000}>
//           <img
//             src="holder.js/800x400?text=Third slide&bg=20232a"
//             alt="Third slide"
//           />
          
//         </Carousel.Item>
//       </Carousel>
//   )
// }

// export default ImageSlider

import React,{ useState }  from 'react';
import ImageSliderStyle from './ImageSliderStyle';

import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
const images=[
  "/images/img-1.png",
  "/images/img-2.jpg",
  "/images/img-3.jpg",
  "/images/img-4.jpg",
];

const ImageSlider = ({slides}) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () =>{
    setCurrent(current === length - 1 ? 0 : current +1)
  }

  const prevSlide = () =>{
    setCurrent(current === 0 ? length - 1:current -1)
  }
console.log(current,'current');
  return (
    <ImageSliderStyle>
    <div className="slider">
      <PlayCircleOutlineOutlinedIcon className="left_arrow" onClick={prevSlide} />
      <PlayCircleOutlineOutlinedIcon className="right_arrow" onClick={nextSlide}/>
      {
        images.map((slide,index)=>(
          <div className={index===current?'slide active':'slide'} key={index}>
            {index===current &&(
            <img src={slide}  alt='travel image'/>)
            }
          </div>
        ))
      }
    </div>
    </ImageSliderStyle>
  )
}

export default ImageSlider

