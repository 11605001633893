import React from 'react'

const Info=()=> {
  return (
    <div className="info">
    <span>Welcome to Simple Travel</span>
      <ul className="description">
      <p>
      Simple Travel Inc. is an established Travel  & Tour Agency in New York City. It has been registered in New York on Nov 2011. We would like to extend a hearty welcome to you by rendering our services to help in your travel holidays arrangement for Nepal, Tibet. We make your tailor made program for Trekking in Nepal, Tour Packages in Nepal, Nepal Tours, Peak Climbing, Jungle Safari, Incentive Tour Packages, River Rafting, Hotel Reservation, Honeymoon Tours, Tours in Nepal, Tibet Tour, Trekking in Tibet, for  Travel Agents, Tour Operators, Tour Agency & Independent Traveler's according to your interest. As a Travel Agent & Local Tour Operator in Kathmandu Nepal, we have designed the website in a way which should make it easy for you to get information and ideas for your holidays. It is probably the best comprehensive detailed website for Travel and Tour Agents or individual traveller’s in the region of Asia.
      We are ready for your assistance 24×7 & 365 days. Email Us: info@simpletrvl.com
      </p>
      
      <img className="info__img" src="/images/img-5.jpg"/>
      
      </ul>
    </div>
  )
}

export default Info
