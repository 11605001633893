import styled from 'styled-components';

const ImageSliderStyle = styled.div`
.slider{
  position: relative;
  display:flex;
  justify-content:center;
  align-items:center;
  padding: 16px;
}
.left_arrow{
  position:absolute;
  top:50%;
  left:32px;
  font-size: 32px;
  color: #1e73be;
  z-index: 10;
  cursor:pointer;
  user-select: none;
  transform: rotate(
    180deg
    );
}
.right_arrow{
  position:absolute;
  top:50%;
  right:32px;
  font-size: 32px;
  color: #1e73be;
  z-index: 10;
  cursor:pointer;
  user-select: none;
}
.slide{
  opacity:0;
  transition-duration: 1s ease;
}
.active{
  opacity:1;
  transition-duration: 1s;
  transform:scale(1.08);
}
`;

export default ImageSliderStyle;