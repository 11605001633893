import React, { Fragment } from 'react';
import classNames from 'classnames';
import IconStyled from './IconStyled';
import icons from '../../icon.svg';

const Icon = ({
  name, className, primary, secondary, small, active, disabled, iconName, iconHeight, iconWidth, ...otherProps
}) => {
  const classes = classNames(
    'icon', {
      primary,
      secondary,
      small,
      active,
      disabled,
    }, className, name,
  );
  return (
    <Fragment>
      <IconStyled iconName={iconName} iconWidth={iconWidth} iconHeight={iconHeight} disabled={disabled}>
        <svg className={classes} {...otherProps}>
          <use xlinkHref={`${icons}#${iconName}`} />
        </svg>
      </IconStyled>
    </Fragment>
  );
};

export default Icon;
