import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MenuBarStyle from './MenuBarStyle';
import MenuIcon from '@material-ui/icons/Menu';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { MenuBarData } from './MenuBarData';

const MenuBar=()=> {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  return (
    <MenuBarStyle>
      <div className="menuBar">
        <div className="title"><span className="icon" onClick={handleClick}>{click ?<CloseRoundedIcon/>:<MenuIcon/>}</span><span className="title-main">Simple Travel</span>
        
         </div>
         {/* <ul className={click?'nav-menu active':'nav-menu'}>
          <li className="nav-item">
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/about-us' className='nav-links' onClick={closeMobileMenu}>
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/contact-us' className='nav-links' onClick={closeMobileMenu}>
              Contact Us
            </Link>
          </li>
         </ul> */}
      <Link  to="/">Home</Link>
      {/* <Link to="about-us">About</Link> */}
      <Link to="contact-us">Contact Us</Link>
      </div>
      <nav className={click? 'nav-menu active':'nav-menu'}>
         <ul className='nav-menu-items'>
           <li className="navbar-toggle">
             <Link to='#' className='menu-bars'>
             <CloseRoundedIcon onClick={handleClick}/>
             </Link>
           </li>
           {MenuBarData.map((item,index)=>{
             return (
               <li key={index} className={item.cName}>
                 <Link to={item.path}>
                   <span>{item.title}</span>
                 </Link>
               </li>
             )
           })}
         </ul>
      </nav>
    </MenuBarStyle>
  )
}

export default MenuBar;

// import React, { useState, useEffect } from 'react';
// // import { Button } from './Button';
// import { Link } from 'react-router-dom';
// import MenuBarStyle from './MenuBarStyle';
// import MenuIcon from '@material-ui/icons/Menu';
// import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

// function Navbar() {
//   const [click, setClick] = useState(false);
//   const [button, setButton] = useState(true);

//   const handleClick = () => setClick(!click);
//   const closeMobileMenu = () => setClick(false);

//   const showButton = () => {
//     if (window.innerWidth <= 960) {
//       setButton(false);
//     } else {
//       setButton(true);
//     }
//   };

//   useEffect(() => {
//     showButton();
//   }, []);

//   window.addEventListener('resize', showButton);

//   return (
//     <MenuBarStyle>
//       <nav className='navbar'>
//         <div className='navbar-container'>
//           <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
//             TRVL
//             <i class='fab fa-typo3' />
//           </Link>
//           <div className='menu-icon' onClick={handleClick}>
//             {/* <i className={click ? 'fas fa-times' : 'fas fa-bars'} /> */}
//            <span onClick={handleClick}>{click ?<CloseRoundedIcon/>:<MenuIcon/>}</span>
//           </div>
//           <ul className={click ? 'nav-menu active' : 'nav-menu'}>
//             <li className='nav-item'>
//               <Link to='/' className='nav-links' onClick={closeMobileMenu}>
//                 Home
//               </Link>
//             </li>
//             <li className='nav-item'>
//               <Link
//                 to='/services'
//                 className='nav-links'
//                 onClick={closeMobileMenu}
//               >
//                 Services
//               </Link>
//             </li>
//             <li className='nav-item'>
//               <Link
//                 to='/products'
//                 className='nav-links'
//                 onClick={closeMobileMenu}
//               >
//                 Products
//               </Link>
//             </li>

//             <li>
//               <Link
//                 to='/sign-up'
//                 className='nav-links-mobile'
//                 onClick={closeMobileMenu}
//               >
//                 Sign Up
//               </Link>
//             </li>
//           </ul>
//           {/* {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>} */}
//         </div>
//       </nav>
//     </MenuBarStyle>
//   );
// }

// export default Navbar;

