import React from 'react'
import MenuBar from '../MenuBar'
import TopHeaderStyled from './TopHeaderStyle'

const TopHeader=()=> {
  return (
    <TopHeaderStyled>
      <div className="topheader">
        <div className="topheader__logo">
        <h1>Simple Travel</h1>
        </div>
        <div className="topheader__contact">
          <div className="callUs">
            Call us Today
            </div>
          <span className="number"><a href="tel:212-470-9058">212-470-9058</a></span>
          <span className="tollfree">
            <p>Toll free :</p>
            <a href="tel:1-800-381-9207">1-800-381-9207</a>
          </span>
          <span className="tollfree">
            <p>Email :</p>
            <a href="mailto:info@simpletrvl.com">info@simpletrvl.com</a>
          </span>
        </div>
      </div>
      <MenuBar/>
    </TopHeaderStyled>
  )
}

export default TopHeader
