import { Button } from '@material-ui/core';
import React,{ useState } from 'react';
import emailjs from 'emailjs-com';
import FooterStyle from './FooterStyle'
import { Link } from 'react-router-dom';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

const Footer=()=> {
  const initialdata = {
    name:'',
    email:'',
    subject:'',
    message:'',
  };
  const sendEmail=(e)=> {
    e.preventDefault();
  
    emailjs.sendForm('service_o80en7v', 'template_cgxojr9', e.target, 'user_BHTX0Q8IgsCPViwIpbyIk')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      setData(initialdata);
  };
  const [data, setData] = useState(initialdata);
  const onInputChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }
  console.log(data,'data');
  return (
    <FooterStyle>
    <div className="footer">
    {/* <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the Adventure newsletter to receive our best vacation deals
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section> */}
      <div className="footer-links">
      <div className="footer-link-wrapper">
      <div class='footer-link-items'>
        <div className="footer__text__title">
          <h2>Contact Us</h2>
        </div>
        <div className="footer__text__desc">
          <p>Phone: <a href="tel:212-470-9058">212-470-9058</a></p>
          <p>Email: <a href="mailto:info@simpletrvl.com">info@simpletrvl.com</a></p>
          <p><a href="tel:1-800-381-9207">Toll Free: 1-800-381-9207</a></p>
          <p>42-42 80th St Suite 4P Elmhurst NY 11373</p>
        </div>
        </div>
      </div>
      
      <div className="footer-link-wrapper">
      <form onSubmit={(e)=>sendEmail(e)} name="contact__form" className="form-details">
          <div className="contact-details" data-aos="fade-up">
            <input type="text" placeholder="Enter your name" value={data.name} name="name" onChange={event => onInputChange(event)} required/>
            <input type="email" placeholder="Enter email address"  name="email" value={data.email} onChange={event => onInputChange(event)} required/>
            <input type="text" placeholder="Enter subject" name="subject" value={data.subject} onChange={event => onInputChange(event)} required/>
          </div>
          <div className="message" data-aos="fade-left">
            <textarea name="message" id="" cols="30" rows="10" placeholder="Enter message" name="message"
          value={data.message} onChange={event => onInputChange(event)} required></textarea>
            <Button type="submit" variant="contained" color="primary">Send Message</Button>
          </div>
        </form>
      </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              Simple Travel
              <i class='fab fa-typo3' />
            </Link>
          </div>
          <small class='website-rights'>Simple Travel © 2021</small>
          <div class='social-icons'>
            <a
              href='https://www.facebook.com/SimpleTravel'
              target='_blank'
              aria-label='Facebook'
            >
              <FacebookIcon />
            </a>
            {/* <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <InstagramIcon />
            </Link> */}
            {/* <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <TwitterIcon />
            </Link> */}
          </div>
        </div>
      </section>
    </div>
    </FooterStyle>
  )
}

export default Footer
