import styled from 'styled-components';

const FormStyle = styled.div`
padding: 40px 150px;
.form-group{
  width: 48%;
  padding: 24px 0px;
  .form-input-wrap{
    display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 24px;
      input {
        padding: 14px;
        min-width: 222px;
      }
      textarea{
        min-width: 222px;
      }
  }
}
@media screen and (max-width: 768px) {
  padding: 40px 50px;
    .form-group{
      width: 100%;
    }
  }
`;
export default FormStyle;