import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

const Cards = () => {
  return (
    <div className='cards'>
      <h1>Special Package to Nepal</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/img-6.jpg'
              text='Competetive Price for Anywhere in the world'
              label='Travel'
              path='/contact-us'
            />
            <CardItem
              src='images/img-7.jpg'
              text='Travel through the Cities of Kathmandu'
              label='Travel'
              path='/contact-us'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/img-8.jpg'
              text='Experience the thrill of Bungy Jumping'
              label='Adrenaline'
              path='/contact-us'
            />
            <CardItem
              src='images/trek.jpg'
              text='Embark on scenic trekking adventures'
              label='Adventure'
              path='/contact-us'
            />
            <CardItem
              src='images/img-9.jpg'
              text='Ride through Chitwan on a Jungle Safari'
              
              label='Adventure'
              path='/contact-us'
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Cards
