import styled from 'styled-components';

const HomeStyle = styled.div`
width: 100%;
img{
  height: 390px;
  vertical-align: top;
  }
}
.support{
  height: 58px;
  padding: 16px 150px;
  background-color: #fefbf0;
  svg{
    width: 24px;
    height: 24px;
    color: #1e73be;
    margin-right: 16px;
  }
  @media screen and (max-width: 768px) {
    padding: 16px 60px;
  }
}
.card{
  display: flex;
  height: 345px;
  background: #ebebeb;
  padding: 5px 150px;
  .card_one,.card__two,.card__three{
    width: 35%;
    margin: 5px;
    background: white;
    .card__image{
      border-style: solid;
      border-color: #FFFFFF;
      border-radius: 0px 0px 0px 0px;
      box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 50%);
      img{
        height: 295px;
      }
    }
    label{
      color: rgba(0, 0, 0, 0.81);
      background-color: #FFFFFF;
      font-size: 25px;
      font-weight: 600;
      text-transform: uppercase;
      font-style: normal;
      margin-top: 5px;
    }
  }
  }
}
.info{
  padding: 50px 150px;
  display: flex;
  flex-flow: column;
  .info__img:hover {
    transform: scale(1.1);
  }
  span{
    font-size: 38px;
  }
  .description{
    display: flex;
    @media screen and (max-width: 768px) {
    flex-direction: column;
    p{
      padding-right:0px !important;
    }
    }
    p{
      text-align: justify;
      padding: 5px 0px; 
      padding-right: 50px;
    }
  }
  img{
    height: 320px;
    width: 35%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  @media screen and (max-width: 768px) {
    padding:4rem;
    img{
      width: 100%;

    }
  }

`;

export default HomeStyle;